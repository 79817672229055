import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
import classNames from 'classnames'

import CloseButton from '../CloseButton'

import logo from './img/widgetLogo.png'

import styles from './support.module.scss'

const SupportWidget = ({ isSupportWidgetOpen, supportWidgetClose }) => {
  const [isOpen, changeIsOpen] = useState(false)
  const [isOpenButtonAnimate, changeIsOpenButtonAnimate] = useState(true)
  const [isContentShow, changeIsContentShow] = useState(false)

  const onOpen = () => {
    changeIsOpen(true)
    changeIsOpenButtonAnimate(false)
    setTimeout(() => changeIsContentShow(true), 400)
  }

  const onClose = () => {
    supportWidgetClose()
    changeIsContentShow(false)
    changeIsOpen(false)
    setTimeout(() => changeIsOpenButtonAnimate(true), 400)
  }

  useEffect(() => {
    if (isSupportWidgetOpen) {
      onOpen()
    }
  }, [isSupportWidgetOpen])

  return (
    <div className={styles.widget}>
      <div className={classNames(styles.container, !isOpen && styles.containerClose)}>
        <button
          onClick={onOpen}
          className={classNames(
            styles.openButton,
            isOpenButtonAnimate && styles.openButtonAnimate
          )}
        >
          <span className={styles.title}>Помощь</span>
        </button>
        <div className={classNames(styles.contentWrap, isOpen && styles.contentWrapOpen)}>
          <div className={classNames(styles.header, isContentShow && styles.headerShow)}>
            <span className={styles.title}>Помощь</span>
            <CloseButton onClick={onClose} stylesWrap={styles.closeButton} />
          </div>
          <div className={classNames(styles.content, isContentShow && styles.contentShow)}>
            <div className={styles.logoWrap}>
              <img alt="tome" src={logo} className={styles.logo} />
              <i className={styles.logoIco} />
            </div>
            <p className={styles.email}>support@tome.ru</p>
            <p className={styles.text}>
              {'Напишите нам и мы ответим максимально быстро.'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

SupportWidget.propTypes = {
  isSupportWidgetOpen: PropTypes.bool.isRequired,
  supportWidgetClose: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const {
    App: {
      isSupportWidgetOpen,
    },
  } = state
  return {
    isSupportWidgetOpen,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    supportWidgetClose: () => dispatch(actions.supportWidgetClose()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportWidget)
