import NProgress from 'nprogress'
import { notificationPopup } from '../Constants/helpers'

const ApiRequest = ({
  dispatchFunctions = {
    success: () => {},
    error: () => {},
  },
  request = () => {},
  values = {},
  errorTitle = '',
  callback = () => {},
  successCallback = () => {},
  errorCallback = () => {},
}) => {
  NProgress.start()
  request(values).end((err, res) => {
    NProgress.done()
    if (!res) {
      notificationPopup({
        type: 'error',
        message: errorTitle,
        description: 'Произошла неизвестная ошибка',
      })
      dispatchFunctions.error()
      callback()
      return
    }

    if (res.statusCode === 500) {
      errorCallback()
    }

    if (res.statusCode !== 200) {
      const error = res.body
      if (error && error.violations) {
        dispatchFunctions.error(error)
      } else {
        notificationPopup({
          type: 'error',
          message: errorTitle,
          description: 'Произошла неизвестная ошибка',
        })
        dispatchFunctions.error(res.body)
      }
      callback()
      return
    }

    if (res.statusCode === 200 || res.ok) {
      if (res.body) {
        dispatchFunctions.success(res.body)
      } else {
        notificationPopup({
          type: 'error',
          message: errorTitle,
          description: 'Произошла неизвестная ошибка',
        })
        dispatchFunctions.error(res.body)
      }
      callback()
      successCallback()
    }
  })
}

export default ApiRequest
