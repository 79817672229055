import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from "react-device-detect"

import Api from '../../Api/ingex'
import apiRequest from '../../Api/request'

import Presenter from './presenter'
import PresenterMobile from './presenter.mobile'

class Profile extends PureComponent {
  constructor(props) {
    super(props)
    this.innRef = React.createRef()
    // this.birthAtRef = React.createRef()
    // this.lastNameRef = React.createRef()
    // this.firstNameRef = React.createRef()
    // this.seriesRef = React.createRef()
    // this.numberRef = React.createRef()

    this.state = {
      isOnlineAnketaLoading: false,
      registered: false,
      inn: '',
      // birthAt: '',
      // firstName: '',
      // lastName: '',
      // series: '',
      // number: '',
      errors: [],
    }
    this.getData()
  }

  getData = () => {
    const { publicId } = this.props
    apiRequest({
      dispatchFunctions: {
        success: (res) => {
          this.setState({
            isOnlineAnketaLoading: false,
            registered: res.registered,
            inn: res.inn || '',
          })
        },
        error: (res) => {
          this.props.setErrorRequest()
          this.setState({
            isOnlineAnketaLoading: false,
            errors: res.violations || [],
          })
          this.focusOnFirstError()
        },
      },
      request: values => Api.getData(values),
      values: { publicId },
      errorTitle: 'Ошибка входа',
      errorCallback: () => this.props.set500Error(),
    })
  }

  focusOnFirstError = () => {
    const { errors } = this.state
    if (errors.length) {
      const isErrorInclude = inputName => !!errors.find(error => error.parameter === inputName)

      if (isErrorInclude('inn')) {
        this.innRef.current.focusInput()
      }
      // else if (isErrorInclude('passport.first_name')) {
      //   this.firstNameRef.current.focusInput()
      // } else if (isErrorInclude('passport.last_name')) {
      //   this.lastNameRef.current.focusInput()
      // } else if (isErrorInclude('passport.birth_at')) {
      //   this.birthAtRef.current.focusInput()
      // } else if (isErrorInclude('passport.series')) {
      //   this.seriesRef.current.focusInput()
      // } else if (isErrorInclude('passport.number')) {
      //   this.numberRef.current.focusInput()
      // }
    }
  }

  handleChangeInput = (e, errorName) => {
    const { name, value } = e.target
    this.setState(prevState => ({
      [name]: value,
      errors: prevState.errors.map((errors) => {
        if (errors.parameter === errorName) {
          return {}
        }
        return errors
      }),
    }))
  }

  handleSubmitForm = () => {
    const { publicId } = this.props
    const {
      inn,
      // birthAt,
      // lastName,
      // firstName,
      // series,
      // number,
    } = this.state

    this.setState({ isOnlineAnketaLoading: true })

    const body = {
      inn,
      // passport: {
      //   birth_at: birthAt,
      //   last_name: lastName,
      //   first_name: firstName,
      //   series,
      //   number,
      // },
    }

    apiRequest({
      dispatchFunctions: {
        success: () => {
          this.setState({ isOnlineAnketaLoading: false })
          const { history: { push } } = this.props
          push(`/${publicId}`)
        },
        error: (res) => {
          this.setState({
            isOnlineAnketaLoading: false,
            errors: res.violations || [],
          })
          this.focusOnFirstError()
        },
      },
      request: values => Api.profileRequest(values),
      values: { publicId, body },
      errorTitle: 'Ошибка входа',
      errorCallback: () => this.props.set500Error(),
    })
  }

  render() {
    const {
      isOnlineAnketaLoading,
      registered,
      inn,
      errors,
      // birthAt,
      // lastName,
      // firstName,
      // series,
      // number,
    } = this.state
    const {
      innRef,
      // birthAtRef,
      // lastNameRef,
      // firstNameRef,
      // seriesRef,
      // numberRef,
      handleChangeInput,
      handleSubmitForm,
    } = this

    if (isMobile) {
      return (
        <PresenterMobile
          {...{
            isOnlineAnketaLoading,
            registered,
            handleChangeInput,
            handleSubmitForm,
            errors,
            innRef,
            inn,
            // birthAtRef,
            // birthAt,
            // lastNameRef,
            // lastName,
            // firstNameRef,
            // firstName,
            // seriesRef,
            // series,
            // numberRef,
            // number,
          }}
        />
      )
    }

    return (
      <Presenter
        {...{
          isOnlineAnketaLoading,
          registered,
          handleChangeInput,
          handleSubmitForm,
          errors,
          innRef,
          inn,
          // birthAtRef,
          // birthAt,
          // lastNameRef,
          // lastName,
          // firstNameRef,
          // firstName,
          // seriesRef,
          // series,
          // numberRef,
          // number,
        }}
      />
    )
  }
}

Profile.propTypes = {
  set500Error: PropTypes.func,
  setErrorRequest: PropTypes.func,
  publicId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default Profile