import React from 'react'
import PropTypes from 'prop-types'
import {
  Form as AntForm,
} from 'antd'
import ReactInputMask from 'react-input-mask'

import { getInvalidText, getIsInputInvalid } from '../../Constants/helpers'

import {
  Input,
  // Button,

  MobileForm,
  MobileFormBlock,
} from '../../Components'

import styles from './stylesMobile.module.scss'

const FormItem = AntForm.Item

function Presenter({
  isOnlineAnketaLoading,
  handleChangeInput,
  handleSubmitForm,
  errors,
  innRef,
  inn,
  // birthAtRef,
  // birthAt,
  // lastNameRef,
  // lastName,
  // firstNameRef,
  // firstName,
  // seriesRef,
  // series,
  // numberRef,
  // number,
}) {
  return (
    <MobileForm
      formSubmit={handleSubmitForm}
      isLoading={isOnlineAnketaLoading}
      title="Профиль"
      text="Данные профиля используются для расчётов и документов."
      handleSubmit={handleSubmitForm}
      submitText="Сохранить"
    >
      <MobileFormBlock className={styles.formBlockInn}>
        <FormItem
          hasFeedback
          validateStatus={getIsInputInvalid({ inputName: 'inn', errors }) ? 'error' : ''}
        >
          <ReactInputMask
            mask="999999999999"
            maskPlaceholder=""
            value={inn}
            onChange={e => handleChangeInput(e, 'inn')}
          >
            <Input
              formInput
              first
              ref={innRef}
              size="small"
              label="Ваш ИНН"
              name="inn"
              placeholder="12 цифр"
              isInvalid={getIsInputInvalid({ inputName: 'inn', errors })}
              invalidTooltipText={getInvalidText({ inputName: 'inn', errors })}
            />
          </ReactInputMask>
        </FormItem>
        <p className={styles.innInfoText}>
          ИНН можно узнать по паспортным данным на сайте ФНС
        </p>
      </MobileFormBlock>
      {/*
      <MobileFormBlock>
        <FormItem
          hasFeedback
          validateStatus={getIsInputInvalid({ inputName: 'passport.first_name', errors }) ? 'error' : ''}
        >
          <Input
            formInput
            ref={firstNameRef}
            size="small"
            label="Имя"
            name="firstName"
            isInvalid={getIsInputInvalid({ inputName: 'passport.first_name', errors })}
            invalidTooltipText={getInvalidText({ inputName: 'passport.first_name', errors })}
            onChange={handleChangeInput}
            value={firstName}
          />
        </FormItem>
        <FormItem
          hasFeedback
          validateStatus={getIsInputInvalid({ inputName: 'passport.last_name', errors }) ? 'error' : ''}
        >
          <Input
            formInput
            first
            ref={lastNameRef}
            size="small"
            label="Фамилия"
            name="lastName"
            isInvalid={getIsInputInvalid({ inputName: 'passport.last_name', errors })}
            invalidTooltipText={getInvalidText({ inputName: 'passport.last_name', errors })}
            onChange={handleChangeInput}
            value={lastName}
          />
        </FormItem>
        <FormItem
          hasFeedback
          validateStatus={getIsInputInvalid({ inputName: 'passport.birth_at', errors }) ? 'error' : ''}
        >
          <ReactInputMask mask="99.99.9999" maskPlaceholder="" value={birthAt} onChange={handleChangeInput}>
            <Input
              formInput
              last
              ref={birthAtRef}
              size="small"
              label="День рождения"
              name="birthAt"
              isInvalid={getIsInputInvalid({ inputName: 'passport.birth_at', errors })}
              invalidTooltipText={getInvalidText({ inputName: 'passport.birth_at', errors })}
              placeholder="дд.мм.гггг"
            />
          </ReactInputMask>
        </FormItem>
      </MobileFormBlock>
      <MobileFormBlock>
        <FormItem
          hasFeedback
          validateStatus={getIsInputInvalid({ inputName: 'passport.series', errors }) ? 'error' : ''}
        >
          <Input
            formInput
            first
            ref={seriesRef}
            size="small"
            name="series"
            label="Серия паспорта"
            placeholder="Если есть"
            maxlength={20}
            isInvalid={getIsInputInvalid({ inputName: 'passport.series', errors })}
            invalidTooltipText={getInvalidText({ inputName: 'passport.series', errors })}
            onChange={handleChangeInput}
            value={series}
          />
        </FormItem>
        <FormItem
          hasFeedback
          validateStatus={getIsInputInvalid({ inputName: 'passport.number', errors }) ? 'error' : ''}
        >
          <Input
            formInput
            last
            ref={numberRef}
            size="small"
            name="number"
            label="Номер паспорта"
            maxlength={20}
            isInvalid={getIsInputInvalid({ inputName: 'passport.number', errors })}
            invalidTooltipText={getInvalidText({ inputName: 'passport.number', errors })}
            onChange={handleChangeInput}
            value={number}
          />
        </FormItem>
      </MobileFormBlock>
      */}
    </MobileForm>
  )
}

Presenter.propTypes = {
  isOnlineAnketaLoading: PropTypes.bool.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  innRef: PropTypes.shape({}).isRequired,
  inn: PropTypes.string.isRequired,
  // birthAtRef: PropTypes.shape({}).isRequired,
  // birthAt: PropTypes.string.isRequired,
  // lastNameRef: PropTypes.shape({}).isRequired,
  // lastName: PropTypes.string.isRequired,
  // firstNameRef: PropTypes.shape({}).isRequired,
  // firstName: PropTypes.string.isRequired,
  // seriesRef: PropTypes.shape({}).isRequired,
  // series: PropTypes.string.isRequired,
  // numberRef: PropTypes.shape({}).isRequired,
  // number: PropTypes.string.isRequired,
}

export default Presenter
