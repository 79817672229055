import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

import apiRequest from '../../Api/request'
import Api from '../../Api/ingex'
import { notificationPopup } from '../../Constants/helpers'

import Presenter from './presenter'
import PresenterMobile from './presenter.mobile'

class FnsRegistration extends React.PureComponent {
  static propTypes = {
    set500Error: PropTypes.func,
    setErrorRequest: PropTypes.func,
    publicId: PropTypes.string.isRequired,
    backUrl: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      hash: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props)
    this.timerTime = 5000
    this.maxRepeatCount = 20
    this.componentMount = true
    this.binaryNumberArray = this.getBinaryNumberArray()
    this.interval = null
    this.intervalTime = 500

    this.state = {
      isContinueDisable: true,
      isShowPopup: false,
      popupText: '',
      binded: false,
      binding_id: null,
      binding_status: '',
      binaryNumber: this.binaryNumberArray[Math.floor(Math.random() * this.binaryNumberArray.length)],
      isShowRebind: false,
      repeatCount: 0,
    }
  }

  componentDidMount() {
    this.profileBind()
  }

  componentWillUnmount() {
    this.componentMount = false
    if (this.interval) {
      clearInterval(this.interval)
    }
    if (this.profileBindTimeout) {
      this.setState({ repeatCount: this.maxRepeatCount })
      clearTimeout(this.profileBindTimeout)
    }
  }

  getProfile = () => {
    const { binding_status } = this.state
    if (binding_status === 'canceled') {
      this.profileRebind()
    } else {
      this.profileBind(true, true)
    }
  }

  profileBind = (isTurnOnRepeat, isCheckBindId) => {
    this.setBinaryNumber()
    const { publicId } = this.props
    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          const {
            npd,
            npd: {
              binding_status,
              binded,
            },
          } = body

          if (binded) {
            if (this.interval) {
              clearInterval(this.interval)
            }
            this.setState({
              repeatCount: 0,
              isShowPopup: true,
              isShowRebind: false,
              popupText: 'Всё получилось!',
            })
          } else if (binding_status === 'pending' && !npd.binding_id) {
            if (this.interval) {
              clearInterval(this.interval)
            }
            this.setState({
              repeatCount: 0,
              isShowPopup: true,
              isShowRebind: true,
              popupText:
                <>
                  Получите статус
                  <br />
                  самозанятого
                </>
            })

            if (isCheckBindId) {
              const { binding_status: stateBindingStatus } = this.state
              if ((stateBindingStatus === binding_status) && !npd.binding_id) {
                notificationPopup({
                  type: 'error',
                  description: 'Статус не изменился.',
                })
              }
            }
          } else if (binding_status === 'pending' && npd.binding_id) {
            if (isTurnOnRepeat) {
              const { repeatCount } = this.state
              const isRepeatable = !!(this.maxRepeatCount - repeatCount)

              if (isRepeatable) {
                this.setState({
                  repeatCount: repeatCount + 1,
                  isShowPopup: true,
                  isShowRebind: false,
                  popupText:
                    <>
                      Отправили запрос
                      <br />
                      на подключение
                    </>
                })
                this.profileBindTimeout = setTimeout(() => this.profileBind(true), this.timerTime)
              } else {
                this.setState({
                  repeatCount: 0,
                  isShowPopup: true,
                  isShowRebind: true,
                  popupText:
                    <>
                      Мы долго ждали,
                      <br />
                      попробуйте снова
                    </>
                })
              }
            } else {
              if (this.interval) {
                clearInterval(this.interval)
              }
              this.setState({
                repeatCount: 0,
                isShowPopup: true,
                isShowRebind: false,
                popupText:
                  <>
                    Отправили запрос
                    <br />
                    на подключение
                  </>
              })
              this.profileBind(true)
            }
          } else if (binding_status === 'canceled') {
            if (this.interval) {
              clearInterval(this.interval)
            }
            this.setState({
              repeatCount: 0,
              isShowPopup: true,
              isShowRebind: true,
              popupText:
                <>
                  Подключить не удалось,
                  <br />
                  попробуйте снова
                </>
            })
          }

          this.updateNpdInfo(body.npd)
        },
        error: () => {
          this.props.setErrorRequest()
        },
      },
      request: values => Api.bindedRequest(values),
      values: { publicId },
      errorTitle: 'Ошибка входа',
      errorCallback: () => this.props.set500Error(),
    })
  }

  profileRebind = () => {
    this.setBinaryNumber()
    const { publicId } = this.props
    const { binding_status } = this.state
    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          if (binding_status === 'canceled' && body.npd.binding_status === 'canceled') {
            if (this.interval) {
              clearInterval(this.interval)
            }
            notificationPopup({
              type: 'error',
              description: 'Статус не изменился.',
            })
          }
          if (body.npd.binding_status === 'pending' && !body.npd.binded) {
            this.profileBind(!!body.npd.binding_id)
            this.setState({
              repeatCount: 0,
              isShowPopup: true,
              isShowRebind: false,
              popupText:
                <>
                  Отправили запрос
                  <br />
                  на подключение
                </>
            })
          }
          this.updateNpdInfo(body.npd)
        },
        error: () => {
          this.props.setErrorRequest()
        },
      },
      request: values => Api.rebindRequest(values),
      values: { publicId },
      errorTitle: 'Ошибка входа',
      errorCallback: () => this.props.set500Error(),
    })
  }

  updateNpdInfo = (npd) => {
    const {
      binded,
      binding_id,
      binding_status,
    } = npd
    if (!binded && this.interval) {
      clearInterval(this.interval)
    }
    this.setState({
      isContinueDisable: !binded,
      binded,
      binding_id: binding_id || null,
      binding_status: binding_status || '',
    })
  }

  setBinaryNumber = () => {
    this.interval = setInterval(() => {
      if (this.componentMount) {
        this.setState({
          binaryNumber: this.binaryNumberArray[Math.floor(Math.random() * this.binaryNumberArray.length)],
        })
      }
    }, this.intervalTime)
  }

  getBinaryNumber = () => `${
    Math.round(Math.random())
  }${
    Math.round(Math.random())
  }${
    Math.round(Math.random())
  }${
    Math.round(Math.random())
  }${
    Math.round(Math.random())
  }`

  getBinaryNumberArray = () => {
    const array = []
    for (let i = 0; i < 20; i += 1) {
      array.push(this.getBinaryNumber())
    }
    return array
  }

  render() {
    const {
      location: {
        pathname,
        hash,
        search,
      },
      history: { push },
      backUrl,
    } = this.props
    const {
      binded,
      binding_id,
      binding_status,
      binaryNumber,
      isContinueDisable,
      isShowPopup,
      popupText,
      isShowRebind,
    } = this.state
    const {
      componentMount,
      getProfile,
    } = this

    const isCancelDisable = binded || binding_id !== null || binding_status !== 'pending'

    if (isMobile) {
      return (
        <PresenterMobile
          isCancelDisable={isCancelDisable}
          {...{
            pathname,
            hash,
            search,
            push,
            backUrl,
            binded,
            binaryNumber,
            isContinueDisable,
            isShowPopup,
            popupText,
            isShowRebind,
            componentMount,
            getProfile,
          }}
        />
      )
    }

    return (
      <Presenter
        isCancelDisable={isCancelDisable}
        {...{
          pathname,
          hash,
          search,
          push,
          backUrl,
          binded,
          binaryNumber,
          isContinueDisable,
          isShowPopup,
          popupText,
          isShowRebind,
          componentMount,
          getProfile,
        }}
      />
    )
  }
}

export default FnsRegistration
