import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

import Presenter from './presenter'
import PresenterMobile from './presenter.mobile'

export default class AntInput extends React.PureComponent {
  static propTypes = {
    readOnly: PropTypes.bool,
    id: PropTypes.string,
    isInvalid: PropTypes.bool,
    isDisable: PropTypes.bool,
    isAuto: PropTypes.bool,
    formInput: PropTypes.bool,
    first: PropTypes.bool,
    last: PropTypes.bool,
    noBorder: PropTypes.bool,
    autoSize: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({}),
    ]),
    textArea: PropTypes.bool,
    numberInput: PropTypes.bool,
    invalidTooltipText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    tooltipPlacement: PropTypes.oneOf([
      'top', 'left', 'right', 'bottom',
      'topLeft', 'topRight', 'bottomLeft', 'bottomRight',
      'leftTop', 'leftBottom', 'rightTop', 'rightBottom',
    ]),
    wrapStyles: PropTypes.string,
    tooltipClassNames: PropTypes.string,
    name: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    addonBefore: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    prefix: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    suffix: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    styles: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    autoFocus: PropTypes.bool,
    pattern: PropTypes.instanceOf(RegExp),
    label: PropTypes.string,
    labelClassName: PropTypes.string,
  }

  static defaultProps = {
    autoComplete: 'off',
    placeholder: '',
    type: 'text',
    size: 'default',
    autoFocus: false,
    isDisable: false,
    isAuto: false,
    isInvalid: false,
    formInput: false,
    numberInput: false,
    textArea: false,
    tooltipPlacement: 'topRight',
    invalidTooltipText: '',
    label: null,
    // onChange: () => {},
  }

  constructor(props) {
    super(props)

    this.ref = React.createRef()

    this.state = {
      isInputFocus: false,
    }
  }

  focusInput = () => {
    if (isMobile && this.props.formInput) {
      this.ref.current.focus()
    } else if (this.props.textArea) {
      if (this.ref.current.resizableTextArea) {
        this.ref.current.resizableTextArea.textArea.focus()
      } else {
        this.ref.current.textArea.focus()
      }
    } else {
      if (this.ref.current.inputNumberRef) {
        this.ref.current.inputNumberRef.focus()
      } else {
        this.ref.current.input.focus()
      }
    }
  }

  _onFocusWrap = () => this.setState({ isInputFocus: true })

  _onBlurWrap = () => this.setState({ isInputFocus: false })

  _getIsShowTooltip = () => {
    const {
      isInvalid,
      isDisable,
      invalidTooltipText,
    } = this.props
    const {
      isInputFocus,
    } = this.state

    return (!!invalidTooltipText && !!invalidTooltipText.length)
      && (
        isInputFocus
      )
      && !isDisable
      && isInvalid
  }

  _handleWrapClick = () => {
    this.focusInput()
  }

  render() {
    const {
      textArea,
      numberInput,
      autoSize,
      readOnly,
      id,
      name,
      autoComplete,
      placeholder,
      type,
      size,
      value,
      addonBefore,
      prefix,
      onChange,
      onFocus,
      onBlur,
      autoFocus,
      styles: outStyles,
      isDisable,
      isAuto,
      formInput,
      first,
      last,
      noBorder,
      invalidTooltipText,
      tooltipPlacement,
      wrapStyles,
      pattern,
      tooltipClassNames,
      isInvalid,
      label,
      labelClassName,
      suffix,
      ...otherProps
    } = this.props
    const { isInputFocus } = this.state
    const {
      _getIsShowTooltip: getIsShowTooltip,
      _onFocusWrap: onFocusWrap,
      _onBlurWrap: onBlurWrap,
      _handleWrapClick: handleWrapClick,
    } = this

    if (isMobile && formInput) {
      return (
        <PresenterMobile
          inputRef={this.ref}
          {...{
            first,
            last,
            noBorder,
            textArea,
            isAuto,
            isDisable,
            id,
            type,
            name,
            prefix,
            label,
            labelClassName,
            value,
            placeholder,
            isInvalid,
            invalidTooltipText,
            suffix,
            wrapStyles,
            pattern,
            onChange,
            handleWrapClick,
            onFocusWrap,
            onBlurWrap,
            isInputFocus,
          }}
        />
      )
    }

    return (
      <Presenter
        inputRef={this.ref}
        {...{
          textArea,
          numberInput,
          autoSize,
          readOnly,
          id,
          name,
          autoComplete,
          placeholder,
          type,
          size,
          value,
          addonBefore,
          prefix,
          onChange,
          onFocus,
          onBlur,
          autoFocus,
          outStyles,
          isDisable,
          isAuto,
          formInput,
          invalidTooltipText,
          tooltipPlacement,
          wrapStyles,
          pattern,
          tooltipClassNames,
          isInvalid,
          label,
          labelClassName,
          suffix,
          otherProps,

          getIsShowTooltip,
          onFocusWrap,
          onBlurWrap,
        }}
      />
    )
  }
}
