import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.scss'

export default class Button extends React.PureComponent {
  static propTypes = {
    buttonType: PropTypes.string,
    type: PropTypes.oneOf([
      'yellow',
      'white',
    ]),
    size: PropTypes.oneOf([
      'default',
      'small',
      'large',
    ]),
    icon: PropTypes.element,
    wrapStyle: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    onClick: PropTypes.func,
    isDisable: PropTypes.bool,
    isLoading: PropTypes.bool,
    target: PropTypes.oneOf(['_blank', '_parent']),
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        state: PropTypes.shape({}),
      }),
    ]),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
      PropTypes.string,
    ]),
  }

  static defaultProps = {
    type: 'white',
    buttonType: 'button',
    size: 'default',
    target: '_blank',
    isDisable: false,
    isLoading: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  buttonContent = () => {
    const {
      icon,
      children,
    } = this.props

    return (
      <>
        {icon && (
          <span
            className={classNames(
              styles.iconWrap,
              children && styles.iconWrapMargin
            )}
          >
            {icon}
          </span>
        )}
        {children && (
          <>
            {typeof children === 'string'
              ? <span className={styles.text}>{children}</span>
              : children
            }
          </>
        )}
      </>
    )
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    const {
      buttonType,
      type,
      size,
      wrapStyle,
      onClick,
      isDisable,
      isLoading,
      target,
      to,
    } = this.props
    const { buttonContent } = this

    let button = (
      <button
        type={buttonType}
        className={classNames(
          styles.button,
          styles[type],
          styles[`${size}Size`],
          isDisable && styles[`${type}Disable`],
          isLoading && styles[`${type}Loading`],
          wrapStyle
        )}
        onClick={onClick}
      >
        {buttonContent()}
      </button>
    )

    if (to) {
      button = (
        <a
          href={to}
          target={target}
          rel="noopener noreferrer"
          className={classNames(
            styles.button,
            styles.buttonLink,
            styles[type],
            styles[`${size}Size`],
            isDisable && styles[`${type}Disable`],
            isLoading && styles[`${type}Loading`],
            wrapStyle
          )}
          onClick={onClick}
        >
          {buttonContent()}
        </a>
      )
    }

    return button
  }
}
