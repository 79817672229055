import React from 'react'
import PropTypes from 'prop-types'
import { Input, InputNumber, Tooltip } from 'antd'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

import styles from './styles.module.scss'

const { TextArea } = Input

function Presenter({
  textArea,
  numberInput,
  autoSize,
  readOnly,
  id,
  name,
  autoComplete,
  placeholder,
  type,
  size,
  value,
  addonBefore,
  prefix,
  onChange,
  onFocus,
  onBlur,
  autoFocus,
  outStyles,
  inputRef,
  isDisable,
  isAuto,
  invalidTooltipText,
  tooltipPlacement,
  wrapStyles,
  pattern,
  tooltipClassNames,
  isInvalid,
  label,
  labelClassName,
  suffix,
  otherProps,

  getIsShowTooltip,
  onFocusWrap,
  onBlurWrap,
}) {
  const getInput = () => {
    if (textArea) {
      return (
        <TextArea
          ref={inputRef}
          onChange={isAuto ? () => {} : onChange}
          {...{
            autoSize,
            readOnly,
            id,
            name,
            autoComplete,
            placeholder,
            type,
            size,
            value,
            prefix,
            onFocus,
            onBlur,
            autoFocus,
            pattern,
            ...otherProps,
          }}
          disabled={isDisable}
          className={classNames(
            outStyles,
            isAuto && 'ant-input-affix-wrapper-auto'
          )}
        />
      )
    }
    if (numberInput) {
      return (
        <InputNumber
          ref={inputRef}
          onChange={isAuto ? () => {} : onChange}
          {...{
            readOnly,
            id,
            name,
            autoComplete,
            type,
            size,
            value,
            addonBefore,
            prefix,
            onFocus,
            onBlur,
            autoFocus,
            pattern,
            ...otherProps,
          }}
          placeholder={isMobile ? (label || placeholder) : placeholder}
          disabled={isDisable}
          className={classNames(
            outStyles,
            isAuto && 'ant-input-affix-wrapper-auto'
          )}
        />
      )
    }
    return (
      <Input
        ref={inputRef}
        onChange={isAuto ? () => {} : onChange}
        {...{
          readOnly,
          id,
          name,
          autoComplete,
          type,
          size,
          value,
          addonBefore,
          prefix,
          onFocus,
          onBlur,
          autoFocus,
          pattern,
          ...otherProps,
        }}
        placeholder={!isAuto && (isMobile ? (label || placeholder) : placeholder)}
        disabled={isDisable}
        className={classNames(
          outStyles,
          isAuto && 'ant-input-affix-wrapper-auto'
        )}
      />
    )
  }

  return (
    <div
      onFocus={onFocusWrap}
      onBlur={onBlurWrap}
      className={styles.wrap}
    >
      <div className={styles.row}>
        {(!isMobile && label) && (
          <h6 className={classNames(styles.label, labelClassName)}>
            { label }
          </h6>
        )}
        <Tooltip
          placement={tooltipPlacement}
          title={invalidTooltipText || ''}
          visible={!!getIsShowTooltip()}
          overlayClassName={classNames('input-tooltip', tooltipClassNames)}
        >
          <div className={classNames(styles.inputWrap, wrapStyles)}>
            {getInput()}
            <div className={styles.suffixWrap}>
              {isAuto
                ? (
                  <>
                    {!textArea && <span className={styles.inputSuffix}>Авто</span>}
                  </>
                )
                : suffix
              }
            </div>
            {isInvalid && (
              <span
                className={classNames(
                  tooltipClassNames,
                  styles.inputInvalid,
                  textArea && styles.textAreaInvalid
                )}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

Presenter.propTypes = {
  textArea: PropTypes.bool.isRequired,
  numberInput: PropTypes.bool.isRequired,
  autoSize: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  addonBefore: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  prefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  suffix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool.isRequired,
  outStyles: PropTypes.string,
  inputRef: PropTypes.shape({}),
  isDisable: PropTypes.bool.isRequired,
  isAuto: PropTypes.bool.isRequired,
  invalidTooltipText: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string,
  wrapStyles: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  tooltipClassNames: PropTypes.string,
  isInvalid: PropTypes.bool.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  otherProps: PropTypes.any.isRequired,

  getIsShowTooltip: PropTypes.func.isRequired,
  onFocusWrap: PropTypes.func.isRequired,
  onBlurWrap: PropTypes.func.isRequired,
}

export default Presenter
