import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

import Button from '../Button'

import styles from './styles.module.scss'

import infoIcon from './img/info_icon.svg'
import corner from './img/corner_white.svg'

const CardWithDescription = (props) => {
  const {
    wrapStyles,
    contentStyles,
    description,
    isSubmitHide,
    submitText,
    submitButtonStyle,
    isSubmitDisable,
    handleSubmitForm,
    cancelText,
    cancelButtonStyle,
    isCancelDisable,
    handleCancelPress,
    handleCancelPressLink,
    to,
    isLoading,
    footerContent,
    children,
  } = props

  return (
    <div className={classNames('global-flat-box-shadow', styles.wrap, wrapStyles)}>
      <div className={classNames(styles.content, contentStyles)}>
        {children}
      </div>
      <div
        className={classNames(
          styles.bottom,
          (isMobile && isSubmitHide) && styles.bottomHide
        )}
      >
        {!isMobile && (
          <>
            <div className={styles.bottomCornerWrap}>
              <span className={classNames(styles.bottomCornerLine, styles.bottomCornerLineLeft)} />
              <img alt="corner" src={corner} className={styles.bottomCorner} />
              <span className={classNames(styles.bottomCornerLine, styles.bottomCornerLineRight)} />
            </div>
            <div className={styles.helpWrap}>
              <img alt="Info" src={infoIcon} width={9} />
              <p className={styles.description}>
                {description || (
                  <>
                    Данные защищены
                    <br />
                    по стандарту PCI DSS
                  </>
                )}
              </p>
            </div>
          </>
        )}
        <div className={classNames(styles.buttonsWrap, footerContent && styles.buttonsWrapWithContent)}>
          {(!isMobile && footerContent) && footerContent}
          {(cancelText && cancelText !== null) && (
            <>
              {(!!handleCancelPressLink
                && (
                  (handleCancelPressLink && handleCancelPressLink.length > 0)
                  || typeof handleCancelPressLink === 'object')
              )
                ? (
                  <Button
                    type="white"
                    isDisable={isCancelDisable}
                    isLoading={isLoading}
                    to={handleCancelPressLink}
                    onClick={handleCancelPress}
                    wrapStyle={classNames(styles.buttonCancel, cancelButtonStyle)}
                  >
                    {cancelText}
                  </Button>
                )
                : (
                  <Button
                    type="white"
                    isDisable={isCancelDisable}
                    isLoading={isLoading}
                    onClick={handleCancelPress}
                    wrapStyle={classNames(styles.buttonCancel, cancelButtonStyle)}
                  >
                    {cancelText}
                  </Button>
                )
              }
            </>
          )}
          {!isSubmitHide && (
            <>
              {to ? (
                  <Button
                    type="yellow"
                    isDisable={isSubmitDisable}
                    isLoading={isLoading}
                    target="_parent"
                    to={to}
                    wrapStyle={classNames(
                      styles.buttonSubmit,
                      !cancelText && styles.buttonSubmitSolo,
                      submitButtonStyle
                    )}
                  >
                    {submitText}
                  </Button>
                )
                : (
                  <Button
                    type="yellow"
                    isDisable={isSubmitDisable}
                    isLoading={isLoading}
                    onClick={handleSubmitForm}
                    wrapStyle={classNames(
                      styles.buttonSubmit,
                      !cancelText && styles.buttonSubmitSolo,
                      submitButtonStyle
                    )}
                  >
                    {submitText}
                  </Button>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

CardWithDescription.propTypes = {
  wrapStyles: PropTypes.string,
  contentStyles: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isSubmitHide: PropTypes.bool,
  submitText: PropTypes.string,
  submitButtonStyle: PropTypes.string,
  isSubmitDisable: PropTypes.bool,
  to: PropTypes.string,
  handleSubmitForm: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  cancelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  cancelButtonStyle: PropTypes.string,
  isCancelDisable: PropTypes.bool,
  handleCancelPress: PropTypes.func,
  handleCancelPressLink: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  isLoading: PropTypes.bool,
  footerContent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}

CardWithDescription.defaultProps = {
  submitText: 'Пополнить',
  isSubmitDisable: false,
  handleSubmitForm: () => {},
  handleSubmitPress: () => {},
  isFormError: false,
  handleCloseErrorForm: () => {},
}

export default CardWithDescription
