import React from 'react'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'

import Button from '../../Components/Button'

import arrowRight from './img/arrow_right.svg'
import illustration from './img/illustration.png'
import infoIcon from './img/info_icon.svg'
import corner from './img/corner_white.svg'

import styles from './styles.module.scss'
import PropTypes from "prop-types"

const NotFound = ({ supportWidgetOpen }) => (
  <div className={styles.wrap}>
    <Helmet>
      <title>Страница не найдена | .tome</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className={styles.backgroundCircle} />
    <Button
      isExternalLink
      target="_parent"
      to="https://tome.ge/"
      wrapStyle={styles.back}
    >
      <img alt="back" src={arrowRight} className={styles.backImg} />
      <span>Главная</span>
    </Button>
    <div className={styles.box}>
      <div className={styles.content}>
        <img alt="404" src={illustration} className={styles.image} />
        <h2 className={styles.title}>
          Страница не найдена
        </h2>
        <p className={styles.text}>
          {'Такой страницы не\u00A0существует. Попробуйте вернуться на\u00A0главную и\u00A0продолжить.'}
        </p>
      </div>
      <div className={styles.boxBottom}>
        {!isMobile && (
          <>
            <div className={styles.boxBottomCornerWrap}>
              <span className={classNames(styles.boxBottomCornerLine, styles.boxBottomCornerLineLeft)} />
              <img alt="corner" src={corner} className={styles.boxBottomCorner} />
              <span className={classNames(styles.boxBottomCornerLine, styles.boxBottomCornerLineRight)} />
            </div>
            <div className={styles.boxBottomTextWrap}>
              <img alt="Info" src={infoIcon} />
              <div className={styles.boxBottomText}>
                <span>Не переживайте,</span>
                <span>мы всегда на связи</span>
              </div>
            </div>
          </>
        )}
        <Button
          type="white"
          onClick={(e) => {
            e.preventDefault()
            supportWidgetOpen()
          }}
          wrapStyle={styles.boxBottomButton}
        >
          Служба поддержки
        </Button>
      </div>
    </div>
  </div>
)

NotFound.propTypes = {
  supportWidgetOpen: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    supportWidgetOpen: () => dispatch(actions.supportWidgetOpen()),
  }
}

export default connect(null, mapDispatchToProps)(NotFound)
