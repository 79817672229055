import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './mobileInput.module.scss'

function Presenter({
  inputRef,
  textArea,
  isAuto,
  isDisable,
  first,
  last,
  noBorder,
  id,
  type,
  name,
  prefix,
  label,
  labelClassName,
  value,
  placeholder,
  isInvalid,
  invalidTooltipText,
  suffix,
  wrapStyles,
  pattern,
  onFocus,
  onBlur,
  onChange,
  handleWrapClick,
  onFocusWrap,
  onBlurWrap,
  isInputFocus,
}) {
  return (
    <div
      className={classNames(
        styles.wrap,
        isInvalid && styles.wrapInvalid,
        (isDisable || isAuto) && styles.wrapDisable,
        first && styles.wrapFirst,
        last && styles.wrapLast,
        noBorder && styles.wrapNoBorder,
        wrapStyles
      )}
      onFocus={onFocusWrap}
      onBlur={onBlurWrap}
      onClick={handleWrapClick}
    >
      {(label && !invalidTooltipText) && (
        <span className={classNames(styles.label, isInputFocus && styles.labelFocus, labelClassName)}>{label}</span>
      )}
      {(invalidTooltipText) && (
        <span className={classNames(styles.label, labelClassName)}>{invalidTooltipText}</span>
      )}
      <div className={styles.content}>
        {prefix}
        {textArea
          ? (
            <textarea
              id={id}
              ref={inputRef}
              readOnly={isDisable || isAuto}
              value={value}
              onBlur={onBlur}
              onFocus={onFocus}
              name={name}
              type={type}
              onChange={onChange}
              placeholder={!isAuto && placeholder}
              className={classNames(
                styles.input,
                styles.textArea
              )}
            />
          )
          : (
            <input
              id={id}
              ref={inputRef}
              readOnly={isDisable || isAuto}
              value={value}
              onBlur={onBlur}
              onFocus={onFocus}
              pattern={pattern}
              name={name}
              type={type}
              onChange={onChange}
              placeholder={!isAuto && placeholder}
              className={styles.input}
            />
          )
        }
        {suffix && (
          <div className={styles.suffixWrap}>
            {suffix}
          </div>
        )}
      </div>
    </div>
  )
}

Presenter.propTypes = {
  textArea: PropTypes.bool.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  noBorder: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  prefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  suffix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputRef: PropTypes.shape({}),
  isDisable: PropTypes.bool,
  isAuto: PropTypes.bool,
  invalidTooltipText: PropTypes.string,
  wrapStyles: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  handleWrapClick: PropTypes.func,
  onFocusWrap: PropTypes.func.isRequired,
  onBlurWrap: PropTypes.func.isRequired,
  isInputFocus: PropTypes.bool.isRequired,
}

export default Presenter
