import request from 'superagent'

const API_URL = 'https://npd.tome.ge/api'
const API_ENDPOINTS = {
  getData: (publicId) => `${API_URL}/self-employed/${publicId}`,
  sendRequest: (publicId) => `${API_URL}/npd/${publicId}/bind`,
  rebindRequest: (publicId) => `${API_URL}/npd/${publicId}/rebind`,
  profileRequest: (publicId) => `${API_URL}/self-employed/${publicId}/inn/change`,
}

export default class Api {
  static getData({ publicId }) {
    const endpoint = API_ENDPOINTS.getData(publicId)

    return request
      .get(endpoint)
      .accept('application/json')
  }

  static bindedRequest({ publicId }) {
    const endpoint = API_ENDPOINTS.sendRequest(publicId)

    return request
      .post(endpoint)
      .accept('application/json')
  }

  static rebindRequest({ publicId }) {
    const endpoint = API_ENDPOINTS.rebindRequest(publicId)

    return request
      .post(endpoint)
      .accept('application/json')
  }

  static profileRequest({ publicId, body }) {
    const endpoint = API_ENDPOINTS.profileRequest(publicId)

    return request
      .post(endpoint)
      .send(body)
      .accept('application/json')
  }
}
