import PropTypes from 'prop-types'
import { message } from 'antd'

import { isMobile } from "react-device-detect"

export const getIsInputInvalid = ({ inputName, errors }) => {
  if (errors.length) {
    const currentErrors = errors.find(error => error.parameter === inputName)
    return !!currentErrors
  }
  return false
}

export const getInvalidText = ({ inputName, errors }) => {
  if (errors.length) {
    const currentError = errors.find(error => error.parameter === inputName)
    if (currentError) {
      return currentError.description
    }
    return ''
  }

  return ''
}

export const notificationPopup = ({
  type = '',
  duration = 4.5,
  description = '',
}) => {
  const config = {
    key: `open${Date.now()}`,
    duration,
    top: isMobile ? 66 : 76,
  }
  message.destroy()
  message.config(config)

  switch (type) {
    case 'error':
      return message.error(description)
    case 'success':
      return message.success(description)
    case 'info':
      return message.info(description)
    default:
      return message.info(description)
  }
}

notificationPopup.PropTypes = {
  type: PropTypes.oneOf(['info', 'success', 'error']),
  duration: PropTypes.number,
  message: PropTypes.string,
  description: PropTypes.string,
}

export const validURL = str => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i') // fragment locator
  return !!pattern.test(str)
}
