import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

import {
  SelfEmployerInstruction,
  SelfEmployerAnswer,
} from './Pages'

import styles from './styles.module.scss'
import './documents.scss'

import close from './img/close.svg'
import closeMobile from './img/closeMobile.svg'

class DocumentsPopup extends Component {
  static propTypes = {
    hash: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      hash: PropTypes.string,
    }),
    history: PropTypes.shape({
      goBack: PropTypes.func,
      push: PropTypes.func,
    }),
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto'
  }

  handleClosePage = () => {
    const {
      location: { pathname },
      history: { push },
    } = this.props
    push(pathname)
  }

  getPage = () => {
    const { hash } = this.props.location
    switch (hash) {
      case '#document-selfEmployer-instruction':
        return <SelfEmployerInstruction />
      case '#document-selfEmployer-answer':
        return <SelfEmployerAnswer />
      default:
        return <SelfEmployerInstruction />
    }
  }

  render() {
    const {
      handleClosePage,
      getPage,
    } = this

    return (
      <div className={styles.wrap}>
        <div className={styles.card}>
          <div className={styles.content}>
            <div className={classNames('base-layout', styles.base)}>
              <div className={styles.contentWrap}>
                { getPage() }
              </div>
            </div>
            {!isMobile && (
              <div className={styles.closeWrap}>
                <div className={styles.closeBase}>
                  <button className={styles.close} onClick={handleClosePage}>
                    <img alt="close" src={close} />
                  </button>
                </div>
              </div>
            )}
          </div>
          {isMobile && (
            <button className={styles.mobileClose} onClick={handleClosePage}>
              <img alt="close" src={closeMobile} className={styles.mobileCloseIcon} />
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(DocumentsPopup)
