import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'

import { CardWithDescription } from '../../Components'

import illustration from './illustration.png'

import styles from './error.module.scss'

const ErrorAppCap = ({ supportWidgetOpen }) => (
  <div className={styles.wrap}>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className={styles.backgroundCircle} />
    <div className={styles.box}>
      <CardWithDescription
        description={(
          <>
            Не переживайте,
            <br />
            мы всегда поможем
          </>
        )}
        cancelText="Служба поддержки"
        handleCancelPress={supportWidgetOpen}
        isSubmitHide
      >
        <Helmet>
          <title>Временная ошибка :. tome</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className={styles.card}>
          {!isMobile && <img alt={illustration} src={illustration} className={styles.image} />}
          <h2 className={classNames(styles.title, isMobile && styles.titleMobile)}>
            { 'Ой, что-то не так!' }
          </h2>
          <p className={styles.text}>
            { 'Наши сотрудники уже работают над устранением неполадок и\u00A0скоро все починят.' }
          </p>
        </div>
      </CardWithDescription>
    </div>
  </div>
)

ErrorAppCap.propTypes = {
  supportWidgetOpen: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    supportWidgetOpen: () => dispatch(actions.supportWidgetOpen()),
  }
}

export default connect(null, mapDispatchToProps)(ErrorAppCap)
