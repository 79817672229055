import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Popover } from 'antd'

import CardWithDescription from '../../Components/CardWithDescription'
import Button from '../../Components/Button'

import cloud from './img/cloud.svg'
import logo from './img/logo.svg'
import fnsIco from './img/fns.png'
import circle from './img/circle.svg'

import styles from './styles.module.scss'

function Presenter({
  pathname,
  hash,
  search,
  push,
  backUrl,
  binded,
  binaryNumber,
  isContinueDisable,
  isCancelDisable,
  isShowPopup,
  popupText,
  isShowRebind,
  componentMount,
  getProfile,
}) {
  return (
    <CardWithDescription
      isSubmitDisable={isContinueDisable}
      cancelText="Мой ИНН"
      isCancelDisable={isCancelDisable}
      handleCancelPress={() => push(`${pathname}/profile${hash}${search}`)}
      submitText="Продолжить"
      to={backUrl}
      description={(
        <>
          Если не получилось
          <br />
          пишите, мы поможем
        </>
      )}
    >
      <div className={styles.content}>
        <div className={styles.imageWrap}>
          <img
            alt="Регистрирация в ФНС"
            src={cloud}
            width={273}
            height={168}
            className={styles.imageCloud}
          />
          <Popover
            visible={componentMount && isShowPopup}
            placement="top"
            content={popupText}
            overlayClassName="custom-popover"
          >
            <div className={styles.imageBankWrap}>
              {(isShowRebind && !binded)
                ? (
                  <Button type="yellow" wrapStyle={styles.requestButton} onClick={getProfile}>
                    <img alt="circle" src={circle} className={styles.requestButtonImage} />
                  </Button>
                )
                : <img
                  alt="fns"
                  src={fnsIco}
                  width={35}
                  height={34}
                />
              }
            </div>
          </Popover>
          <div className={styles.imageLineWrap}>
            <span className={styles.numbersText}>{isContinueDisable ? binaryNumber : '00101'}</span>
            <div className={styles.imageLine} />
          </div>
          <div className={styles.imageLogoWrap}>
            <img alt="tome" src={logo} width={22} />
          </div>
        </div>
        <h1 className={styles.title}>
          {'Подключение к ФНС'}
        </h1>
        <p className={styles.text}>
          {'Для подключения нужен '}
          <NavLink
            to="#document-selfEmployer-instruction"
            className={styles.capTextLink}
          >
            { 'статус' }
          </NavLink>
          {' самозанятого и\u00A0'}
          <NavLink
            to="#document-selfEmployer-answer"
            className={styles.capTextLink}
          >
            { 'ваш ответ' }
          </NavLink>
          {' в\u00A0приложении "Мой налог".'}
        </p>
      </div>
    </CardWithDescription>
  )
}

Presenter.propTypes = {
  pathname: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  backUrl: PropTypes.string.isRequired,
  binded: PropTypes.bool.isRequired,
  binaryNumber: PropTypes.string.isRequired,
  isContinueDisable: PropTypes.bool.isRequired,
  isCancelDisable: PropTypes.bool.isRequired,
  isShowPopup: PropTypes.bool.isRequired,
  popupText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  isShowRebind: PropTypes.bool.isRequired,
  componentMount: PropTypes.bool.isRequired,
  getProfile: PropTypes.func.isRequired,
}

export default Presenter