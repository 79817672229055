import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Route, Switch, withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import normalizeUrl from 'normalize-url'

import ErrorAppCap from './Pages/ErrorAppCap'
import NotFound from './Pages/NotFound'
import FnsRegistration from './Pages/FnsRegistration'
import Profile from './Pages/Profile'
import DocumentsPopup from "./Pages/DocumentsPopup"

import { SupportWidget } from './Components'
import { validURL } from './Constants/helpers'

import logo from './img/logo.svg'
import logoMobile from './img/logo_mobile.svg'
import closeIcon from './img/close.svg'
import closeRedIcon from './img/close_red.svg'
import crossBlack from './img/cross.svg'
import mimi from './img/mimi.svg'
import line from './img/line.svg'
import arrow from './img/arrow_right_small.svg'

import styles from './App.module.scss'

class App extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      hash: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props)

    let publicId = ''
    const pathname = this.props.location.pathname
    const isErrorRequest = !(pathname.length > 1)
    if (pathname.length > 1) {
      publicId = pathname.split('/')[1]

    }
    this.state = {
      publicId,
      isErrorRequest,
      hasError: false,
      isHttp500ServerError: false,
    }
    this.clearUrl()
    this.backUrl = this.getBackUrl()
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidUpdate = () => {
    window.scrollTo(0, 0)
  }

  set500Error = () => this.setState({ isHttp500ServerError: true })

  clearUrl = () => {
    const {
      hash,
      search,
    } = this.props.location
    let pathname = this.props.location.pathname
    const lastEl = pathname.split('')[pathname.length - 1]
    if (lastEl === '/') {
      pathname = pathname.substring(0, pathname.length - 1)
      this.props.history.push(`${pathname}${hash}${search}`)
    }
  }

  getBackUrl = () => {
    const { search } = this.props.location
    if (search.length > 0) {
      const backUrl = search.split('=')[1]
      if (backUrl.length > 0) {
        if (validURL(backUrl)) {
          return normalizeUrl(backUrl)
        }
        return 'https://tome.ge/'
      }
    }
    return 'https://tome.ge/'
  }

  setErrorRequest = () => {
    this.setState(prevState => {
      if (!prevState.isErrorRequest) {
        return {
          isErrorRequest: true,
        }
      }
    })
  }

  getIsDocumentsPopupShow = () => {
    const {
      location: {
        hash,
      },
    } = this.props
    const isDocumentsHash = hash.includes('document-')
    return hash && hash.length && isDocumentsHash
  }

  header = ({ title, handleClose }) => (
    <div className={styles.header}>
      <div className={styles.logoLink}>
        <img alt="Tome" src={isMobile ? logoMobile : logo} className={styles.logo} />
      </div>
      <div className="base-layout">
        <div className={styles.headerContent}>
          {!isMobile && (
            <>
              <div className={styles.headerLeft}>
                <div className={classNames(styles.headerTitleWrap, styles.headerTitleWrapChoose)}>
                  <h1 className={styles.headerTitle}>
                    {title}
                  </h1>
                </div>
                {handleClose && (
                  <button
                    className={styles.close}
                    onClick={handleClose}
                  >
                    <img alt="Close" src={closeIcon} width={16} className={styles.closeIcon} />
                    <img alt="Close" src={closeRedIcon} width={16} className={styles.closeRedIcon} />
                  </button>
                )}
              </div>
              <img alt="mimi" src={mimi} className={styles.mimiImage} />
            </>
          )}
          {(isMobile && title === 'Изменение ИНН')
            ? (
              <button
                onClick={handleClose}
                className={classNames(styles.signIn, styles.closeMobile)}
              >
                <img alt="" src={line} className={styles.signInLine} />
                <img alt="" src={crossBlack} width={isMobile ? 18 : 14} />
              </button>
            )
            : (
              <a
                title={this.backUrl}
                href={this.backUrl}
                className={styles.signIn}
              >
                <img alt="" src={line} className={styles.signInLine} />
                <span>Вернуться</span>
                <img alt="->" src={arrow} className={styles.signInImage} />
              </a>
            )
          }
        </div>
      </div>
    </div>
  )

  render() {
    if (this.state.hasError || this.state.isHttp500ServerError) {
      return (
        <>
          <ErrorAppCap />
          {!isMobile && <SupportWidget />}
        </>
      )
    }

    const { isErrorRequest } = this.state

    if (isErrorRequest) {
      return (
        <>
          <NotFound/>
          {!isMobile && <SupportWidget />}
        </>
      )
    }

    const {
      history: { push },
      location: {
        hash,
        search,
      },
    } = this.props
    const {
      publicId,
    } = this.state
    const {
      backUrl,
      header,
      set500Error,
      setErrorRequest,
      getIsDocumentsPopupShow,
    } = this

    return (
      <>
        <div className={styles.wrap}>
          {!isMobile && <div className={styles.backgroundCircle} />}
          <Switch>
            <Route
              exact
              path="/:id"
              render={(routeProps) => (
                <>
                  {header({ title: 'Подключение к ФНС' })}
                  <div className="base-layout">
                    <div className={styles.contentWrap}>
                      <FnsRegistration
                        {...routeProps}
                        set500Error={set500Error}
                        setErrorRequest={setErrorRequest}
                        publicId={publicId}
                        backUrl={backUrl}
                      />
                    </div>
                  </div>
                </>
              )}
            />
            <Route
              exact
              path="/:id/profile"
              render={(routeProps) => (
                <>
                  {header({ title: 'Изменение ИНН', handleClose: () => push(`/${publicId}${hash}${search}`) })}
                  <div className="base-layout">
                    <div className={styles.contentWrap}>
                      <Profile
                        {...routeProps}
                        set500Error={set500Error}
                        setErrorRequest={setErrorRequest}
                        publicId={publicId}
                      />
                    </div>
                  </div>
                </>
              )}
            />
          </Switch>
        </div>
        {getIsDocumentsPopupShow() && <DocumentsPopup />}
        {!isMobile && <SupportWidget />}
      </>
    )
  }
}

export default withRouter(App)
