import React from 'react'

import Button from '../../../../Components/Button'

import itunes from './img/itunes.svg'
import googlePlay from './img/googlePlay.svg'
import img1 from './img/img_01.png'
import img2 from './img/img_02.png'
import img3 from './img/img_03.png'
import img4 from './img/img_04.png'
import img5 from './img/img_05.png'
import img6 from './img/img_06.png'
import img7 from './img/img_07.png'

const SelfEmployerInstruction = () => (
  <div id="document">
    <aside>
      <span>3 декабря 2019 г.</span>
    </aside>
    <h2>
      Инструкция: получаем статус самозанятого
    </h2>
    <p>
      Чтобы использовать новый специальный налоговый режим, нужно пройти регистрацию
      и получить подтверждение. Без регистрации применение налогового режима и формирование чеков невозможно.
    </p>
    <h3>
      Через приложение
    </h3>
    <p>
      Федеральная налоговая служба РФ разработала специальное приложение, через которое можно
      зарегистрироваться самостоятельно, — «Мой налог». Скачать его можно в App Store и Google Play.
    </p>
    <div className="document_buttons">
      <Button
        isExternalLink
        to="https://itunes.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D0%BD%D0%B0%D0%BB%D0%BE%D0%B3/id1437518854?l=en&mt=8"
        size="large"
        wrapStyle="document_button"
      >
        <img alt="itunes" src={itunes} style={{ marginTop: -3 }} className="document_button_icon" />
        { 'Скачать из App Store' }
      </Button>
      <Button
        isExternalLink
        to="https://play.google.com/store/apps/details?id=com.gnivts.selfemployed&hl=ru"
        size="large"
        wrapStyle="document_button"
      >
        <img alt="googlePlay" src={googlePlay} className="document_button_icon" />
        { 'Скачать из Google Play' }
      </Button>
    </div>
    <p>
      Чтобы начать работать, нужно подтвердить свою личность одним из двух способов:
      через личный кабинет налогоплательщика или ввести паспортные данные вручную.
    </p>
    <p>
      При регистрации через личный кабинет налогоплательщика система попросит ввести ИНН и пароль.
      Чтобы войти в приложение таким способом, понадобится уже иметь учётную запись на сайте nalog.ru или «Госуслуги».
    </p>
    <img alt="selfEmployed" src={img1} className="document_pick" />
    <p>
      Если проходить регистрацию с помощью паспортных данных, приложение попросит ввести номер телефона
      и вышлет код безопасности, а затем предложит выбрать регион, в котором вы работаете.
    </p>
    <img alt="selfEmployed" src={img2} className="document_pick" />
    <p>
      Пока налоговый режим в качестве эксперимента ввели в четырёх регионах — Москве,
      Московской и Калужской областях,а также Республике Татарстан. Однако верифицироваться
      в приложении может человек с регистрацией в любом другом городе.
    </p>
    <img alt="selfEmployed" src={img3} className="document_pick" />
    <p>
      Далее приложению нужно предоставить паспорт с фотографией. «Мой налог» отсканирует документ,
      чтобы подтвердить личность.
    </p>
    <p>
      После этого «Мой налог» попросит сверить паспортные данные с распознанными. Если будет найдена ошибка,
      потребуется снова распознать документ. Если данные введены корректно, нужно нажать «Подтвердить».
    </p>
    <img alt="selfEmployed" src={img4} className="document_pick" />
    <p>
      После этого приложение попросит сфотографироваться — лицо должно быть хорошо видно.
      Это последний шаг в регистрации, затем нужно подтвердить заявление о постановке на учёт или отказаться.
    </p>
    <p>
      Далее нужно выбрать вид деятельности — в приложении 105 вариантов. В отличие от режима ИП,
      самозанятые могут выбрать лишь один вид деятельности.
    </p>
    <img alt="selfEmployed" src={img5} className="document_pick" />
    <p>
      После этого приложением можно пользоваться и самостоятельно фиксировать оплаты. Для этого нужно нажать
      на кнопку «Новая продажа», вписать стоимость и название услуги, указать дату и статус отправителя — физическое
      или юридическое лицо, — а также его номер телефона или электронный адрес. Электронный чек придёт клиенту
      на электронную почту или по SMS.
    </p>
    <img alt="selfEmployed" src={img6} className="document_pick" />
    <p>
      Если клиент захочет вернуть деньги, то в приложении можно скорректировать чек, и тогда сумма налога уменьшится.
    </p>
    <p>
      Сняться с учета в качестве плательщика налога на профессиональный доход можно в любое время.
      Если нет сформированных чеков, дохода и недоимки, это не повлечет никаких последствий.
    </p>
    <p>
      Для прекращения регистрации не нужно писать заявления и лично посещать налоговую инспекцию.
    </p>
    <h3>
      Через личный кабинет
    </h3>
    <p>
      Чтобы зарегистрироваться в качестве плательщика налога на профессиональный доход, не нужно подавать заявления
      и лично посещать налоговую инспекцию. Регистрация происходит через личный кабинет самозанятых на сайте ФНС.
    </p>
    <div className="document_buttons">
      <Button
        isExternalLink
        to="https://lknpd.nalog.ru/auth/login"
        size="large"
        wrapStyle="document_button_inline"
      >
        Кабинет налогоплательщика НПД
      </Button>
    </div>
    <p>
      Есть два способа пройти регистрацию:
      <span>
        1. С помощью ИНН и пароля от личного кабинета налогоплательщика.
      </span>
      <span>
        2. По учетной записи Госуслуг (ЕСИА).
      </span>
    </p>
    <p>
      Вход и регистрация по учетной записи в Единой системе идентификации и аутентификации — это новый способ
      авторизации. Теперь он доступен всем желающим применять льготный режим или войти в личный кабинет и приложение.
    </p>
    <p>
      Если у вас есть подтвержденная учетная запись на Госуслугах, в приложение можно войти без ИНН и пароля от личного
      кабинета.
    </p>
    <img alt="selfEmployed" src={img7} className="document_pick" />
    <p>
      При авторизации по учетной записи Госуслуг регистрация происходит автоматически. Это значит, что те
      налогоплательщики, которые впервые входят в личный кабинет через ЕСИА, после подтверждения своих данных
      и согласия с условиями регистрации, сразу будут поставлены на учет как самозанятые.
    </p>
  </div>
)

export default SelfEmployerInstruction
