import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../Button'

import styles from './mobileForm.module.scss'

const MobileFormBlock = props => (
  <div className={classNames(styles.formBlock, props.className)}>
    { props.children }
  </div>
)

MobileFormBlock.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
}

const MobileForm = props => (
  <form
    onSubmit={props.formSubmit}
    className={classNames(styles.form, props.className)}
  >
    <div className={styles.content}>
      {(props.title || props.text) && (
        <div className={styles.header}>
          <h2 className={styles.title}>{props.title}</h2>
          <p className={styles.subtitle}>{props.text}</p>
        </div>
      )}
      { props.children }
    </div>
    <div className={styles.footer}>
      {props.footer}
      <Button
        type="yellow"
        isLoading={props.isLoading}
        isDisable={props.isDisable}
        onClick={props.handleSubmit}
      >
        {props.submitText}
      </Button>
    </div>
  </form>
)

MobileForm.propTypes = {
  formSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  isLoading: PropTypes.bool,
  isDisable: PropTypes.bool,
  submitText: PropTypes.string,
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.any),
  ]),
}

MobileForm.defaultProps = {
  formSubmit: () => {},
  handleSubmit: () => {},
  submitText: 'Сохранить',
}

export {
  MobileForm,
  MobileFormBlock,
}
