import * as actionTypes from '../actionTypes'

export const initialState = {
  isSupportWidgetOpen: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUPPORT_WIDGET_OPEN:
      return {
        ...state,
        isSupportWidgetOpen: true,
      }

    case actionTypes.SUPPORT_WIDGET_CLOSE:
      return {
        ...state,
        isSupportWidgetOpen: false,
      }

    default:
      return state
  }
}
