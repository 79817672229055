import React from 'react'
import PropTypes from 'prop-types'
import {
  Form as AntForm,
} from 'antd'
import ReactInputMask from 'react-input-mask'

import { getInvalidText, getIsInputInvalid } from '../../Constants/helpers'

import {
  CardWithDescription,
  Input,
  Button,

  SettingsMarkupRow,
  SettingsMarkupContent,
  SettingsMarkupLeftSide,
  SettingsMarkupRightSide,
  // SettingsMarkupLabel,
} from '../../Components'

import wave from './img/wave_settings.png'
import person from './img/person.svg'

import styles from './styles.module.scss'

const FormItem = AntForm.Item

function Presenter({
  isOnlineAnketaLoading,
  handleChangeInput,
  handleSubmitForm,
  errors,
  innRef,
  inn,
  // birthAtRef,
  // birthAt,
  // lastNameRef,
  // lastName,
  // firstNameRef,
  // firstName,
  // seriesRef,
  // series,
  // numberRef,
  // number,
}) {
  return (
    <CardWithDescription
      contentStyles={styles.wrapContent}
      submitText="Сохранить"
      handleSubmitForm={!isOnlineAnketaLoading ? handleSubmitForm : () => {}}
      isLoading={isOnlineAnketaLoading}
      description={(
        <>
          Для расчётов
          <br />
          и документов
        </>
      )}
    >
      <div className={styles.header}>
        <img alt="person" src={person} className={styles.headerPerson} />
        <img alt="wave" src={wave} className={styles.headerWave} />
      </div>
      <form onSubmit={handleSubmitForm} className={styles.form}>
        <SettingsMarkupRow small className={styles.firstRow}>
          <SettingsMarkupContent>
            <FormItem
              hasFeedback
              validateStatus={getIsInputInvalid({ inputName: 'inn', errors }) ? 'error' : ''}
              className={styles.lineInput}
            >
              <ReactInputMask
                mask="999999999999"
                maskPlaceholder=""
                value={inn}
                onChange={e => handleChangeInput(e, 'inn')}
              >
                <Input
                  ref={innRef}
                  size="small"
                  label="Ваш ИНН"
                  name="inn"
                  placeholder="12 цифр"
                  isInvalid={getIsInputInvalid({ inputName: 'inn', errors })}
                  invalidTooltipText={getInvalidText({ inputName: 'inn', errors })}
                />
              </ReactInputMask>
            </FormItem>
          </SettingsMarkupContent>
          <SettingsMarkupContent>
            <SettingsMarkupLeftSide>
              <p className={styles.innInfoText}>
                ИНН можно узнать по паспортным данным на сайте ФНС
              </p>
            </SettingsMarkupLeftSide>
            <SettingsMarkupRightSide className={styles.rightSideBirthday}>
              <Button
                size="small"
                isExternalLink
                type="white"
                to="https://service.nalog.ru/inn.do"
                wrapStyle={styles.innButton}
              >
                Узнать ИНН
              </Button>
            </SettingsMarkupRightSide>
          </SettingsMarkupContent>
        </SettingsMarkupRow>
        {/*
        <SettingsMarkupRow form small>
          <SettingsMarkupContent>
            <FormItem
              hasFeedback
              validateStatus={getIsInputInvalid({ inputName: 'passport.first_name', errors }) ? 'error' : ''}
              className={styles.lineInput}
            >
              <Input
                formInput
                ref={firstNameRef}
                wrapStyles={styles.lineInput}
                size="small"
                label="Имя"
                name="firstName"
                isInvalid={getIsInputInvalid({ inputName: 'passport.first_name', errors })}
                invalidTooltipText={getInvalidText({ inputName: 'passport.first_name', errors })}
                onChange={handleChangeInput}
                value={firstName}
              />
            </FormItem>
          </SettingsMarkupContent>
          <SettingsMarkupContent>
            <FormItem
              hasFeedback
              validateStatus={getIsInputInvalid({ inputName: 'passport.last_name', errors }) ? 'error' : ''}
              className={styles.lineInput}
            >
              <Input
                formInput
                ref={lastNameRef}
                wrapStyles={styles.lineInput}
                size="small"
                label="Фамилия"
                name="lastName"
                isInvalid={getIsInputInvalid({ inputName: 'passport.last_name', errors })}
                invalidTooltipText={getInvalidText({ inputName: 'passport.last_name', errors })}
                onChange={handleChangeInput}
                value={lastName}
              />
            </FormItem>
          </SettingsMarkupContent>
          <SettingsMarkupContent>
            <FormItem
              hasFeedback
              validateStatus={getIsInputInvalid({ inputName: 'passport.birth_at', errors }) ? 'error' : ''}
              className={styles.lineInput}
            >
              <ReactInputMask mask="99.99.9999" maskPlaceholder="" value={birthAt} onChange={handleChangeInput}>
                <Input
                  formInput
                  ref={birthAtRef}
                  wrapStyles={styles.lineInputDate}
                  size="small"
                  label="День рождения"
                  name="birthAt"
                  isInvalid={getIsInputInvalid({ inputName: 'passport.birth_at', errors })}
                  invalidTooltipText={getInvalidText({ inputName: 'passport.birth_at', errors })}
                  placeholder="дд.мм.гггг"
                />
              </ReactInputMask>
            </FormItem>
          </SettingsMarkupContent>
        </SettingsMarkupRow>
        <SettingsMarkupRow form small>
          <SettingsMarkupContent>
            <SettingsMarkupLeftSide>
              <SettingsMarkupLabel>
                { 'Серия и номер пасспорта' }
              </SettingsMarkupLabel>
            </SettingsMarkupLeftSide>
            <SettingsMarkupRightSide className={styles.line}>
              <FormItem
                hasFeedback
                validateStatus={getIsInputInvalid({ inputName: 'passport.series', errors }) ? 'error' : ''}
                className={styles.lineInputSeries}
              >
                <Input
                  formInput
                  ref={seriesRef}
                  wrapStyles={styles.lineInput}
                  size="small"
                  name="series"
                  placeholder="Серия"
                  maxlength={20}
                  isInvalid={getIsInputInvalid({ inputName: 'passport.series', errors })}
                  invalidTooltipText={getInvalidText({ inputName: 'passport.series', errors })}
                  onChange={handleChangeInput}
                  value={series}
                />
              </FormItem>
              <FormItem
                hasFeedback
                validateStatus={getIsInputInvalid({ inputName: 'passport.number', errors }) ? 'error' : ''}
                className={styles.lineInputNumber}
              >
                <Input
                  formInput
                  ref={numberRef}
                  wrapStyles={styles.lineInput}
                  size="small"
                  name="number"
                  placeholder="Номер"
                  maxlength={20}
                  isInvalid={getIsInputInvalid({ inputName: 'passport.number', errors })}
                  invalidTooltipText={getInvalidText({ inputName: 'passport.number', errors })}
                  onChange={handleChangeInput}
                  value={number}
                />
              </FormItem>
            </SettingsMarkupRightSide>
          </SettingsMarkupContent>
        </SettingsMarkupRow>
        */}
      </form>
    </CardWithDescription>
  )
}

Presenter.propTypes = {
  isOnlineAnketaLoading: PropTypes.bool.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  innRef: PropTypes.shape({}).isRequired,
  inn: PropTypes.string.isRequired,
  // birthAtRef: PropTypes.shape({}).isRequired,
  // birthAt: PropTypes.string.isRequired,
  // lastNameRef: PropTypes.shape({}).isRequired,
  // lastName: PropTypes.string.isRequired,
  // firstNameRef: PropTypes.shape({}).isRequired,
  // firstName: PropTypes.string.isRequired,
  // seriesRef: PropTypes.shape({}).isRequired,
  // series: PropTypes.string.isRequired,
  // numberRef: PropTypes.shape({}).isRequired,
  // number: PropTypes.string.isRequired,
}

export default Presenter
