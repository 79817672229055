import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import crossGray from './img/cross_gray.svg'
import crossRed from './img/cross_red.svg'

import styles from './styles.module.scss'

const CloseButton = ({
  onClick = () => {},
  innerLink,
  innerLinkUrl,
  stylesWrap,
}) => {
  const content = () => (
    <>
      <img
        alt="close"
        src={crossGray}
        className={classNames(styles.closeCross, styles.closeCrossGray)}
      />
      <img
        alt="close"
        src={crossRed}
        className={classNames(styles.closeCross, styles.closeCrossRed)}
      />
    </>
  )

  if (innerLink) {
    return (
      <NavLink
        to={innerLinkUrl}
        className={classNames(styles.close, stylesWrap)}
        onClick={onClick}
      >
        { content() }
      </NavLink>
    )
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(styles.close, stylesWrap)}
    >
      { content() }
    </button>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  innerLink: PropTypes.bool,
  innerLinkUrl: PropTypes.string,
  stylesWrap: PropTypes.string,
}

CloseButton.defaultProps = {
  innerLink: false,
  onClick: () => {},
}

export default CloseButton
